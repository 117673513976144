import React, { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useUserContext } from "../../context/userContext"
import axios from "axios"
import GLOBAL from "../../config/variables"
import ROUTES from "../../components/routesConstants"
import { auth } from "../../firebase"
import DonorStatus from "./Components/HomeButton"

//Prime React
import { Button } from "primereact/button"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Toast } from "primereact/toast"

//Loader
import { LoaderMedium, LoaderRotation } from "../../components/Loader/loaders"

//Helpers
import colorSelector from "../../Helpers/colorSelector"
import getDate from "../../Helpers/getDate"

/**
 * Home page component, shows a list of orders and their status.
 * 
 * This component is the main page of the application. It displays a list of orders
 * with the latest update date, order number, patient ID, blood units count, order status
 * and a button to retry or view the order depending on the status.

 * <Home />
 */
export default function Home() {
  const navigate = useNavigate()
  const { token, user, isLoading } = useUserContext()
  const [loadingOrders, setLoadingOrders] = useState(true)
  const [loadingOrganizations, setLoadingOrganizations] = useState(true)
  //Toast
  const toast = useRef(null)
  //Fetch Variables
  const [organizations, setOrganizations] = useState([])
  const [orders, setOrders] = useState([])

  useEffect(() => {
    if (!token) return
    /**
     * Fetches the list of orders from the server.
     *
     * GETs /orders/listing from the server and sets the orders state with the response data.
     * If the request fails, it logs the error to the console and sets the loadingOrders state to false.
     *
     * @param {string} token - The authorization token to be used in the request header.
     */
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          `${GLOBAL.ORDER_MANAGEMENT_ENDPOINT}/orders/listing`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        if (response.status === 200) {
          setOrders(response.data.response.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.warn("Invalid or expired token, redirecting to login page")
          auth.signOut().then(() => {
            navigate(ROUTES.Login, { replace: true })
          })
        } else {
          navigate(ROUTES.Error, {
            state: { error: error.message },
          })
        }
        console.log(error)
      } finally {
        setLoadingOrders(false)
      }
    }

    fetchOrders()
  }, [token])

  useEffect(() => {
    /**
     * Fetches the list of organizations from the server.
     *
     * GETs /organizations/:organization_id from the server and sets the organizations state with the response data.
     * If the request fails, it logs the error to the console and sets the loadingOrganizations state to false.
     *
     * @param {string} token - The authorization token to be used in the request header.
     * @param {object} user - The current user, obtained from the context.
     */
    const fetchOrganizations = async () => {
      if (!token || !user?.organization_id) return
      try {
        const response = await axios.get(
          `${GLOBAL.ORDER_MANAGEMENT_ENDPOINT}/organizations/${user.organization_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        if (response.status === 200) {
          setOrganizations(response.data.response.data)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoadingOrganizations(false)
      }
    }

    fetchOrganizations()
  }, [token, user])

  /**
   * actionBodyTemplate is a function that renders the button for retrying a failed order in the orders table.
   * It takes a rowData object as a parameter, which contains information about the order to be retried.
   * The function uses a switch statement to determine which button to render based on the order's status.
   * If the order status is "NEW", it renders a "Retry" button that sends a POST request to /orders/process/:order_id
   * when clicked. If the request is successful, it navigates to /order/:order_id.
   * If the order status is "MATCHED", it renders a "View Order" button that navigates to /order/:order_id when clicked.
   * For orders with any other status, it renders a "View Order" button that navigates to /order/:order_id when clicked.
   * @param {object} rowData - The object that contains information about the order to be retried.
   * @returns {JSX.Element} - The JSX element to be rendered in the orders table.
   */
  const actionBodyTemplate = (rowData) => {
    if (rowData.order_status === "NEW") {
      return (
        <div className="flex justify-content-center">
          <Button
            label="Retry"
            className="p-button-dataTable"
            onClick={async () => {
              try {
                const response = await axios.post(
                  `${GLOBAL.ORDER_MANAGEMENT_ENDPOINT}/orders/process/${rowData.order_id}`,
                  {},

                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                // Manage server response, if 200 go to order results
                if (response.status === 200) {
                  navigate(`/order/${rowData.order_id}`)
                }
              } catch (error) {
                // Error handling
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail:
                    "Failed to Retry Order, there is no Patients available for this order",
                })
              }
            }}
          />
        </div>
      )
    } else {
      return (
        <div className="flex justify-content-center">
          <Button
            label="View Order"
            className="p-button-dataTable"
            onClick={() => navigate(`/order/${rowData.order_id}`)}
          />
        </div>
      )
    }
  }

  /**
   * Returns a loading animation if the orders are being fetched.
   */
  if (loadingOrders) {
    return (
      <div
        className="w-full h-screen flex justify-content-center align-items-center"
        style={{ paddingBottom: "10rem" }}
      >
        <LoaderMedium />
      </div>
    )
  }
  if (isLoading) {
    return (
      <div>
        <LoaderRotation />
      </div>
    )
  }
  if (!user || !token) {
    return <div>Error: no user data available.</div>
  }

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex justify-content-between align-items-center">
        <div className="flex flex-column my-6 ml-4">
          <h2 className="m-0">Order List</h2>
        </div>
        {loadingOrganizations ? (
          <div className="mr-4">
            <LoaderRotation />
            <p>
              <strong>Loading Donors...</strong>
            </p>
          </div>
        ) : (
          <DonorStatus countDonors={organizations.count_donors} />
        )}
      </div>

      <section className="mb-8 px-4">
        {!orders || orders.length === 0 ? (
          <h4 className="m-0">
            {organizations.count_donors === 0
              ? "No orders available. Donor inventory upload is needed to start creating new orders."
              : "No orders available. Click new order to create one"}
          </h4>
        ) : (
          <DataTable
            paginator
            rows={15}
            value={orders}
            className="w-full dataTable-home"
            style={{ tableLayout: "fixed", maxWidth: "100%" }}
          >
            <Column
              className="white-space-normal overflow-hidden"
              field="creation_datetime"
              header="Latest Update"
              headerStyle={{ textAlign: "left" }}
              bodyStyle={{ textAlign: "left" }}
              sortable
              style={{ maxWidth: "125px", wordWrap: "break-word" }}
              body={(rowData) => getDate(rowData.creation_datetime)}
            />

            <Column
              className="white-space-normal overflow-hidden"
              field="order_id"
              header="Order Number"
              headerStyle={{ textAlign: "left" }} // Header aligned
              bodyStyle={{ textAlign: "left" }}
              style={{ maxWidth: "125px", wordWrap: "break-word" }}
              sortable
              filter
            />

            <Column
              className="white-space-normal overflow-hidden"
              field="patient_key"
              header="Patient ID"
              headerStyle={{ textAlign: "left" }}
              bodyStyle={{ textAlign: "left" }}
              style={{ maxWidth: "125px", wordWrap: "break-word" }}
              sortable
              filter
            />
            <Column
              className="white-space-normal overflow-hidden"
              field="order_results_count"
              header="Blood Units Count"
              bodyStyle={{ textAlign: "center" }}
              style={{ maxWidth: "125px", wordWrap: "break-word" }}
              sortable
            />
            <Column
              className="white-space-normal overflow-hidden text-center"
              field="order_status"
              header="Order Status"
              headerStyle={{ textAlign: "center" }} // Center Header
              bodyStyle={{ textAlign: "center" }}
              style={{ maxWidth: "125px", wordWrap: "break-word" }}
              filter
              sortable
              body={(rowData) => {
                return colorSelector(rowData.order_status)
              }}
            />

            <Column
              className="white-space-normal overflow-hidden"
              header="Action"
              headerStyle={{ textAlign: "center" }}
              bodyStyle={{ textAlign: "center" }}
              style={{ maxWidth: "125px", wordWrap: "break-word" }}
              body={actionBodyTemplate}
            />
          </DataTable>
        )}
      </section>
    </div>
  )
}
